import { Stack, Text, Title, Progress, Image, createStyles } from '@mantine/core';
import Searching from 'assets/img/searching.gif';
import React, { useState, useEffect } from 'react';

const useStyles = createStyles((theme) => ({
    image: {
        height: 250,
        width: 'auto%',
    },

    noItemsTitle: {
        color: '#242424',
        fontSize: 28,
        [theme.fn.largerThan('md')]: {
            fontSize: 28,
        },
    },

    placeholderContainer: {
        paddingInline: 25,
        gap: 10,
        [theme.fn.largerThan('md')]: {
            paddingInline: 0,
            gap: 0,
        },
    },
}))

const FeedbackLoadingScreen = () => {
    const { classes } = useStyles();
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 80) {
                    clearInterval(interval);
                    return 80;
                }
                return prevProgress + 1;
            });
        }, 400);

        return () => clearInterval(interval);
    }, []);

    return (
        <Stack sx={{ height: '100%' }} align={'center'} mb={'20%'} justify={'center'} spacing={20}>
            <img className={classes.image} src={Searching} alt='Loading...' style={{ marginTop: '20px' }} />
            <Title className={classes.noItemsTitle} sx={{ color: '#242424' }}>
                Looking for top talent...
            </Title>
            <Stack align={'center'} justify={'center'} className={classes.placeholderContainer}>
                <Text color={'#242424'} align={'center'}>
                    Candidates matches coming up, hang tight!
                </Text>
                <Text color={'#242424'} align={'center'}>
                    This process can take a few mins.
                </Text>
            </Stack>
            <Progress
                size="md"
                value={progress}
                radius="xl"
                sx={{ width: '80%', maxWidth: 400, marginTop: 10 }}
            />
        </Stack>
    );

}

export default FeedbackLoadingScreen;