import { zodResolver } from "@hookform/resolvers/zod";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CloseButton,
    createStyles,
    Group,
    Image,
    Input,
    InputWrapper,
    LoadingOverlay,
    MultiSelect,
    MultiSelectValueProps,
    Stack,
    Text,
    Textarea,
    TextInput,
    Title
} from '@mantine/core';
import { useGetCustomerPricingPlanQuery, usePostCustomerCompleteSignupMutation } from "app/services/rolebot";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, NavLink, Link } from "react-router-dom";
import * as z from 'zod';
import LogoLetters from 'assets/img/rolebot-wordmark.svg';
import { useNotifications } from "@mantine/notifications";


const useStyles = createStyles((theme) => ({
    button: {
        marginBottom: '20px',
        [theme.fn.largerThan('sm')]: {
            marginLeft: 'auto',
        },
    },
    member: {
        marginBottom: 24,
        [theme.fn.largerThan('md')]: {
            marginInline: 44,
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '120px',
        [theme.fn.smallerThan('sm')]: {
            paddingTop: '60px',
            paddingLeft: '18px',
            paddingRight: '18px',
        },
    },
    form: {
        width: '100%',
        maxWidth: '600px',
    },
    rolebotStyles: {
        ':hover': {
            boxShadow: `0 0 0 2px #E2D7FB`,
            borderRadius: 5
          },
        ':focus-within': {
            border: '1 px solid #D9A8FF',
            boxShadow: `0 0 0 2px #E2D7FB`,
            borderRadius: 5
          }
          
    },

    dropdownStyles: {
        ':hover': {
            backgroundColor: "#EEEEEE",
        }
    },

    chipStyles: {
        borderRadius: '5px',
        border: '1px solid var(--silver, #C4C4C4)',
        background: 'var(--cultured, #F7F5F5)',
        display:'flex', alignItems: 'center',
        margin: '3px',

        ':hover': {
            border: '1px solid var(--silver, #838485)',
            cursor: 'pointer'
        }
    },

    invalid: {
        border: '1px solid var(--red, #DB706B)',
        background: 'var(--red, #DB706B26)',
    },

    xStyles: {
        color: '#B3B3B3',
        ':hover': {
            color: '#4F4F4F',
        }
    } 
}));

const schema = z.object({
    company_name: z.string().min(1, 'Company name is required'),
    contact_name: z.string().min(1, 'Full name is required'),
    password: z.string()
                .min(8, 'Password must be at least 8 characters long')
                .max(20, 'Password must be less than 20 characters long')
                .refine((v) => v.match(/[A-Z]/), 'Password must contain at least one uppercase letter')
                .refine((v) => v.match(/[a-z]/), 'Password must contain at least one lowercase letter'),
    invite_team_members: z.array(z.string().email('Invalid email address')),
    agreeTerms: z.boolean().refine((v) => v, 'You must agree to the terms and conditions'),
});

const CompleteSubscription = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const { data: cppData, isLoading: cppLoading, isError: cppError} = useGetCustomerPricingPlanQuery({token});
    const [postCustomerCompleteSignup, {isLoading: ccsLoading, isError: ccsError}] = usePostCustomerCompleteSignupMutation();
    const [teamMembersSelected, setTeamMembersSelected] = useState<string[]>([]);
    const [teamMembers, setTeamMembers] = useState<{value: string, label: string, is_valid_email: boolean}[]>([]);
    const [errorEmails, setErrorEmails] = useState<string[]>([]);
    const notifications = useNotifications();


    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
        watch,
        trigger
    } = useForm<z.infer<typeof schema>>({
        defaultValues: {
            company_name: '',
            contact_name: '',
            password: '',
            invite_team_members: [],
            agreeTerms: false,
        },
        resolver: zodResolver(schema),
        mode: 'all'
    });

    useEffect(() => {
        if (cppError) {
            navigate('/');
        }
    }, [cppError]);

    const handleCreateAccount = async (payload: any) => {
        await postCustomerCompleteSignup({ token, payload })
            .unwrap()
            .then((response: any) => {
                notifications.showNotification({
                    title: 'Success',
                    message: 'Account created successfully',
                    color: 'green',
                });
                navigate('/');
            })
            .catch((err: any) => {
                if(err.data.error === 'SOME_USERS_ALREADY_EXIST') {
                    setErrorEmails(err.data.existing_users);
                }
            });
    }

    const termsLabel = (
        <Text color='#838485'>
            I agree to be bound by Rolebot's {' '}
            <Text<'a'>
                component={'a'}
                color={'#0077C3'}
                href={'https://www.rolebot.io/terms-of-service'}
                target={'_blank'}
            >
                 Terms of Service
            </Text> and its {' '}
            <Text<'a'>
                component={'a'}
                color={'#0077C3'}
                href={'https://www.rolebot.io/privacy-policy'}
                target={'_blank'}
            >
                Privacy Policy
            </Text>
        </Text>
    );

    const ItemComponent = ({
        value,
        label,
        is_valid_email,
        onRemove,
        classNames,
        ...others
      }: MultiSelectValueProps & { value: string, is_valid_email: boolean }) => {
        
        const {classes, cx} = useStyles();
        return (
            <div onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }} className={ is_valid_email ? classes.chipStyles : cx(classes.chipStyles, { [classes.invalid]: !is_valid_email }) }>
                
                <span style={{paddingLeft:10,color:'#242424'}}>{label}</span>
                <CloseButton
                    aria-hidden
                    onMouseDown={onRemove}
                    radius={2}
                    variant="transparent"
                    tabIndex={-1}
                    className={classes.xStyles}
                />
            </div>
        )
    }

    return (
        <>
        { cppLoading ? (<LoadingOverlay visible={true} />) : (
        <div className={classes.container}>
            <div className={classes.form}>
                <NavLink to={'/'}>
                    <Image src={LogoLetters} width={180} fit={'contain'} mb={30} />
                </NavLink>
                <Title order={3} mt={60} color={'#242424'}>
                    Welcome to Rolebot!
                </Title>
                <Text mb={40} color={'#4f4f4f'}>
                    Let’s set up your account! This will only take a minute.
                </Text>
                <Stack spacing={'xs'}>
                    <form onSubmit={handleSubmit(handleCreateAccount)} id="create-account-form">
                        <TextInput
                            size={'md'}
                            label="What is the name of your company?"
                            placeholder="ACME"
                            autoComplete="company_name"
                            {...register('company_name')}
                            error={errors?.company_name?.message}
                            tabIndex={1}
                            autoCapitalize={'false'}
                            mb={16}
                        />
                        <TextInput
                            size={'md'}
                            label="What is your full name?"
                            placeholder="Jane Doe"
                            autoComplete="contact_name"
                            {...register('contact_name')}
                            error={errors?.contact_name?.message}
                            tabIndex={1}
                            autoCapitalize={'false'}
                            mb={16}
                        />
            
            
                        <InputWrapper error={errors?.password?.message} size={'md'} mb={20}>
                            <Group position={'apart'} mb={4}>
                                <Text component={'label'} size={'md'} weight={500} color="#242424" htmlFor={'password'}>
                                    Create a password
                                </Text>
                            </Group>
                            <Input
                                id={'password'}
                                sx={{ width: '100%' }}
                                placeholder={'8+ characters'}
                                size={'md'}
                                {...register('password')}
                                tabIndex={2}
                                type={'password'}
                            />
                        </InputWrapper>
                        <Checkbox
                        px={10}
                        pt={20}
                        size={'sm'}
                        {...register('agreeTerms')}
                        label={termsLabel}
                    />
            
                    </form>
                    <Button
                        type="submit"
                        className={classes.button}
                        mt="xl"
                        form="create-account-form"
                        loading={ccsLoading}
                        loaderPosition={'right'}
                        px={20}
                        tabIndex={3}
                        disabled={!isValid}
                    >
                        Create account
                    </Button>
                    <Group align={'center'} position={'center'} spacing={4} className={classes.member}>
                        <Text>Have an account?</Text>
                        <Text component={Link} to={'/'} color={'#7039ED'}>
                            Sign in
                        </Text>
                    </Group>
                </Stack>
            </div>
        </div>)}
        </>
    );
};

export default CompleteSubscription;