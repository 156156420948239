import { Text } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import TitleWithClose from '../components/TitleWithClose';
import { ContextModalProps } from '@mantine/modals';
import { FreemiumExpiredProps } from 'types/modals';

const useStyles = createStyles((theme) => ({

  title: {
    marginLeft: 'auto',
    lineHeight: 1.3,
    textAlign: 'center',
    fontSize: theme.fontSizes.lg,
    color: '#242424',
    fontFamily: 'Roboto',
    fontWeight: 500,
    [theme.fn.largerThan('sm')]: {
      textAlign: 'left',
      marginLeft: '0',
      fontSize: 22,
    },
  },


}));

const FreemiumExpired = ({ id }: ContextModalProps<FreemiumExpiredProps>) => {

  const handleUpgrade = () => {
    window.open(process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + "/pages/pricing", '_blank');
  }

  return (
    <>
      <TitleWithClose id={id} title={'Freemium Expired'} />
      <Text size="md" mb={20}>
        Your freemium plan has expired <span onClick={handleUpgrade} style={{ cursor: 'pointer', color: "#7039ED" }}>Upgrade</span> to continue using Rolebot.
      </Text>
    </>
  );
};

export default FreemiumExpired;