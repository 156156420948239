import { Group, Stack, Tabs, Text, Title, Image, createStyles } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useGetCompanyQuery } from 'app/services/rolebot';
import { Circle } from 'tabler-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import VisaLogo from 'assets/img/logos/visa_logo.png';
import MastercardLogo from 'assets/img/logos/mastercard_logo.png';
import AmexLogo from 'assets/img/logos/amex_logo.png';
import UnionpayLogo from 'assets/img/logos/unionpay_logo.png';
import DiscoverLogo from 'assets/img/logos/discover_logo.png';
import DinersLogo from 'assets/img/logos/diners_logo.png';
import JcbLogo from 'assets/img/logos/jcb_logo.png';
import { useEffect, useState } from 'react';
import moment from 'moment';
import useOpenModal from 'hooks/useOpenModal';
import { getReactivatePlanToastContent, toastSuccess } from 'utils/toastify-messages';
import { usePostReactivatePlanMutation } from 'app/services/rolebot';
import { useLocation } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  cardLogo: {
    width: '32px',
    height: '22px',
  },
  planDetails: {
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: '30px',
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  planDetailsGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const Billing = () => {
  const { classes } = useStyles();
  const { data } = useGetCompanyQuery();
  const isFreemiumAccount = data?.customer_pricing_plan?.subscription_type === 'Freemium';
  const isFreemiumAccountActive = isFreemiumAccount && data?.customer_pricing_plan?.free_trial_ends_at && moment(data?.customer_pricing_plan?.free_trial_ends_at).isSameOrAfter(moment(), 'day');
  const [cardLogo, setCardLogo] = useState('');
  const [postReactivatePlan, { isLoading, isSuccess, isError, reset }] = usePostReactivatePlanMutation();
  const location = useLocation();

  const modals = useModals();
  const {  contactSupport, cancelPlanRenewal } = useOpenModal();

  const openPaymentMethodModal = () => {
    modals.openContextModal('paymentMethod', {
      title: '',
      innerProps: {},
      closeOnClickOutside: false,
    });
  };

  useEffect(() => {
    window.history.replaceState({}, '')
    if (data && data.customer_pricing_plan?.requires_reactivation && (location.state as any)?.reactivate) {
      modals.openContextModal('reactivationFormModal', {
        title: '',
        innerProps: { data },
        closeOnClickOutside: false,
        styles: {
          modal: { width: '602px', padding: '20px !important' },
        },
      });
    }
  }, [data])

  const handleCancelPlanRenewalClick = () => {
    cancelPlanRenewal({
      nextBillingDate: moment.utc(data?.plan_end_date).format('MMM DD, YYYY')
    });
  }

  const handleSelectPlan = () => {
    window.open(process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + "/pages/pricing", '_blank');
  }

  const handleReactivatePlan = async () => {

    await postReactivatePlan({}).unwrap();

    toastSuccess(
      getReactivatePlanToastContent()
    );

  }

  const getBillingDisplayNumbers = () => {
    const lastFour = data?.pm_last_four ? data.pm_last_four.toString() : '';
    return lastFour.padStart(4, '0');
  };

  const getCardLogo = (cardBrand: string | null | undefined) => {
    if (!cardBrand) return '';

    var brands = {
      'visa': VisaLogo,
      'mastercard': MastercardLogo,
      'amex': AmexLogo,
      'unionpay': UnionpayLogo,
      'discover': DiscoverLogo,
      'jcb': JcbLogo,
      'diners': DinersLogo,
      'default': ''
    };

    //as keyof typeof cards => 'visa' | 'mastercard' | 'amex' | 'unionpay' | 'discover' | 'default'
    //we use this so TS knows that cardBrand is a valid key of brands, otherwise it will throw an error
    return brands[cardBrand as keyof typeof brands] || brands['default'];
  };

  useEffect(() => {
    if (data?.pm_type) {
      const cardLogo = getCardLogo(data.pm_type);
      setCardLogo(cardLogo);
    }
  }, [data?.pm_type]);

  return (
    <div>
      <Stack mb={30}>
        <Title order={2} style={{ color: '#242424' }}>
          Billing
        </Title>
      </Stack>

      <Tabs variant={'unstyled'}>
        {data?.is_from_drive_through ? (
          <Tabs.Tab label="Overview">
            <Text
              color={'#242424'}
              mt={20}
              mb={20}
              style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}
            >
              Plan Details
            </Text>
            <Stack className={classes.planDetails}>
              <Group
                className={classes.planDetailsGroup}
              >
                <Stack spacing={2}>
                  <Text color={'#242424'}>Plan</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>{isFreemiumAccount ? 'Freemium' : data?.customer_pricing_plan?.pricing_plan?.name}</Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Role limit</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>{data?.active_role_limit}</Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Billing cycle</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>{isFreemiumAccount ? '-' : data?.customer_pricing_plan?.billing_cycle}</Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Renewal amount</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>${parseInt(data?.customer_pricing_plan?.amount)}</Text>
                </Stack>
                <Stack spacing={2}>
                  <Text color={'#242424'}>Next bill date</Text>
                  <Text color={'#242424'} style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}>{isFreemiumAccount ? '-' : moment.utc(data?.customer_pricing_plan?.next_billing_date).format('MMM DD, YYYY')}</Text>
                </Stack>
              </Group>
              {data?.customer_pricing_plan?.cancelation_date && (
                <Group>
                  <Text
                    color={'red'}
                    style={{ fontFamily: 'Helvetica', fontSize: '16px' }}
                  >Plan has been successfully cancelled</Text>
                </Group>
              )}
              {data?.latest_downgrade && (
                <Group>
                  <Text
                    color={'#4F4F4F'}
                    style={{ fontFamily: 'Helvetica', fontSize: '16px', marginTop: 20 }}
                  >
                    Your downgrade request will take effect on <span style={{ fontWeight: 700 }}>{moment.utc(data?.latest_downgrade?.scheduled_at).format('MMM DD, YYYY')}</span>, reducing your role limit to <span style={{ fontWeight: 700 }}>{data?.latest_downgrade?.quantity}</span>. Please ensure you have that number of active roles by then; otherwise, any extra roles will be closed.
                  </Text>
                </Group>
              )}
              {data?.latest_upgrade && moment.utc(data?.latest_upgrade?.show_message_until).isAfter(moment.utc()) && (
                <Group>
                  <Text
                    color={'#4F4F4F'}
                    style={{ fontFamily: 'Helvetica', fontSize: '16px', marginTop: 20 }}
                  >
                    Your upgrade request was successful and is now in effect. Your new role limit is <span style={{ fontWeight: 700 }}>{data?.latest_upgrade?.quantity}</span>. Please note that your upcoming billing will include the new plan amount (<span style={{ fontWeight: 700 }}>${data?.latest_upgrade?.quantity * data?.latest_upgrade?.product_price}</span>) plus a prorated surcharge for the remaining days until the next billing cycle.
                  </Text>
                </Group>
              )}
              {isFreemiumAccount && !isFreemiumAccountActive && (
                <Group>
                  <Text
                    color={'red'}
                    style={{ fontFamily: 'Helvetica', fontSize: '16px' }}
                  >Your freemium account has expired. Select a plan to keep accessing top talent.</Text>
                </Group>
              )}
            </Stack>

            {data?.customer_pricing_plan?.subscription_type === 'Annual' && (
              <Stack>
                <Text
                  color={'#4F4F4F'}
                  mt={20}
                  style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Helvetica' }}
                >
                  Your company is currently subscribed to a (1) one-year plan with Rolebot, billed monthly.
                </Text>

                <Text
                  color={'#4F4F4F'}
                  style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Helvetica' }}
                >
                  <span>Subscription start date: <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>{moment.utc(data?.plan_start_date).format('MMM DD, YYYY')}</span> </span><br />
                  <span>Subscription end date: <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>{moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}</span> </span>
                </Text>


              </Stack>
            )}
            <Text
              color={'#242424'}
              mt={40}
              style={{ fontFamily: 'Roboto', fontWeight: 500, fontSize: '22px' }}
            >
              Plan Settings & Support
            </Text>
            {isFreemiumAccount && (
              <>
                <Text color={'#242424'} mt={20} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>Select a plan</Text>
                <Text color={'#242424'} mt={8}>Select a plan that fits your needs. For Enterprise options, chat with our sales team for personalized assistance.</Text>
                <Group position={'left'} mt={20}>
                  <RolebotButton type={'neutral'} onClick={handleSelectPlan}>
                    Select your plan
                  </RolebotButton>
                </Group>
              </>
            )}
            <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>Contact Support</Text>
            <Text color={'#242424'} mt={8}>Our support team is here to help with any questions you may have!</Text>
            <Group position={'left'} mt={20} mb={isFreemiumAccount ? 30 : 0}>
              <RolebotButton type={'neutral'} onClick={contactSupport}>
                Contact Support
              </RolebotButton>
            </Group>
            {!isFreemiumAccount && !data?.customer_pricing_plan?.cancelation_date && (
              <>
                <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>Cancel your subscription</Text>
                {data?.customer_pricing_plan?.subscription_type === 'Month to Month' && <Text color={'#242424'} mt={8}>
                  Your active subscription will remain accessible until
                  <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                    {' '}{moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}
                  </span>
                </Text>}
                {data?.customer_pricing_plan?.subscription_type === 'Annual' && (
                  <Text color={'#242424'} mt={8} style={{ maxWidth: 900 }}>
                    Your company is currently subscribed to a (1) one-year plan with Rolebot.
                    If you choose to cancel your membership during this period, your subscription will remain active
                    and will be billed until its conclusion on
                    <span style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                      {' '}{moment.utc(data?.plan_end_date).format('MMM DD, YYYY')}.
                    </span>
                  </Text>
                )}
                <Group position={'left'} mt={20} mb={30}>
                  <RolebotButton
                    type={'stand'}
                    onClick={handleCancelPlanRenewalClick}
                  >
                    Cancel my plan
                  </RolebotButton>
                </Group>
              </>
            )}
            {!isFreemiumAccount && data?.customer_pricing_plan?.cancelation_date && (
              <>
                <Text color={'#242424'} mt={40} style={{ fontFamily: 'Roboto', fontWeight: 500 }}>Reactivate your subscription</Text>
                <Text color={'#242424'} mt={8}>You can reactivate your subscription at any time. If you have questions or would like to explore other options, feel free to reach out to our team.</Text>
                <Group position={'left'} mt={20} mb={30}>
                  <RolebotButton type={'neutral'} onClick={handleReactivatePlan} disabled={moment.utc(data?.plan_end_date).isAfter(moment())}>
                    Reactivate my plan
                  </RolebotButton>
                </Group>
              </>
            )}
          </Tabs.Tab>
        ) : null}
        <Tabs.Tab label="Payment Method">
          <Stack mt={20}>
            <Title order={4} color={'#242424'}>
              Payment Method
            </Title>
          </Stack>
          {Boolean(data?.stripe_id) && (
            <Group mt={30}>
              <Text color={'#242424'}>Credit Card</Text>
              {cardLogo === '' && (
                <FontAwesomeIcon icon={faCreditCard} width={17} height={17} color="#242424" />
              )}
              {cardLogo !== '' && (
                <Image src={cardLogo} className={classes.cardLogo} />
              )}
              <Group noWrap spacing={10}>
                <Group spacing={7} noWrap>
                  <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
                  <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
                  <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
                  <Circle size={6} color={'#4F4F4F'} fill={'#4F4F4F'} />
                </Group>
                <Text>{getBillingDisplayNumbers()}</Text>
              </Group>
            </Group>
          )}
          <Group position={'left'} mt={30}>
            <RolebotButton type={Boolean(data?.stripe_id) ? 'neutral' : 'primary'} onClick={openPaymentMethodModal}>
              {Boolean(data?.stripe_id) ? 'Change Payment Method' : 'Add Payment Method'}
            </RolebotButton>
          </Group>
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Billing;
